import axios from "axios";
import store from "@/store";
import router from '../router/';

const api = axios.create({
    baseURL: (process.env.VUE_APP_API_URL !== undefined) ? process.env.VUE_APP_API_URL : "/api",
});

const getErrorFromHeader = (error) => {
    if(error.response.data?.error) {
        return error.response.data.error;
    }
    return 'Ocorreu um erro na requisição!';
}

// Ao receber uma requisição, esse método é invocado
api.interceptors.response.use(function (response) {
    store.dispatch('spin/noSpinSpin', false);
    return response;
  }, function (error) {
    store.dispatch('spin/noSpinSpin', false);
    if(error.response.status) {
        if(error.response.status == 400) {
            store.dispatch('message/showMessage', {severity: 'error', summary: 'Erro', detail: getErrorFromHeader(error), group: 'br', life: 3000});
        } else if (error.response.status == 401) {
            store.dispatch('message/showMessage', {severity: 'error', summary: 'Erro', detail: 'Sua sessão expirou! Por favor, logue novamente', group: 'br', life: 3000});
            router.push('/logout');
        } else if (error.response.status == 403) {
            store.dispatch('message/showMessage', {severity: 'error', summary: 'Erro', detail: 'Você não tem permissão para acessar este recurso!', group: 'br', life: 3000});
        } else if (error.response.status == 500) {
            store.dispatch('message/showMessage', {severity: 'error', summary: 'Erro', detail: 'Erro interno no servidor. Comunique o analista responsável!', group: 'br', life: 3000});
        }
    }
    return Promise.reject(error);
  });

// Antes da requisição ser realizada ele faz uma interceptação
api.interceptors.request.use(function (config) {
    const loggedUser = JSON.parse(sessionStorage.getItem("user"));
    store.dispatch('spin/noSpinSpin', true);
    // Verifica se o usuário está logado e se há um token JWT, se sim, adiciona-o no header
    if (loggedUser?.attributes.jwt) {
        config.headers = {
            ...config.headers,
            authorization: `Bearer ${loggedUser.attributes.jwt}`,
        };
    }
    return config;
}, function (error) {
    // Do something with request error
    store.dispatch('spin/noSpinSpin', false);
    return Promise.reject(error);
});

export default api;