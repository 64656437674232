import api from "@/service/api";

export default {
    login: (loginData) => {
        return api.post('/login', loginData);
    },
    logout: () => {
        sessionStorage.removeItem('user');
    },
    preLoginBrideGroom: (loginData) => {
        return api.post('/login/pre-login-engaged', loginData);
    },
    verifyBrideGroomToken: (token) => {
        const queryString = token ? "?token=" + token : '';
        return api.get(`/login/verify-token${queryString}`);
    }
}