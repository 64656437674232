<template>
	<div class="grid">
		<div class="col-12 mt-2">
			<p>Versão: {{version}}</p>
		</div>
	</div>
</template>

<script>
import systemService from "@/service/system-service";
import {onMounted, ref} from "vue";

export default {
	name: "ProjectVersionComponent",
	setup() {
		const version = ref('');
		onMounted(() => {
			systemService.getSystemVersion().then((response) => {
				version.value = response.data;
			})
		});
		return {version}
	}
}
</script>

<style scoped>

</style>