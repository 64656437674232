import api from "@/service/api";

export default {
    get: () => {
        return api.get('/bridegroom');
    },

    edit: (brideGrooEditted) => {
        return api.put('/bridegroom', brideGrooEditted);
    },

    restrict: (restrictLogin) => {
        return api.put('/bridegroom/restrict', {restriction: restrictLogin});
    },

    isRestricted: () => {
        return api.get('/bridegroom/restrict');
    }
};