export const message = {
    namespaced: true,
    state: {},
    getters: {
        getMessage(state) {
            return state.msg;
        }
    },
    actions: {
        showMessage({ commit }, msg) {
            commit('changeMessage', msg);
        }
    },
    mutations: {
        changeMessage(state, msg) {
            state.msg = msg;
        }
    }
};
