import loginService from "@/service/login-service";

const loggedUser = JSON.parse(sessionStorage.getItem('user'));

const initialState = loggedUser
    ? { status: { loggedIn: true }, user: loggedUser }
    : { status: { loggedIn: false }, user: null };

export const auth = {
    namespaced: true,
    state: initialState,
    getters: {
        isAuthenticated(state) {
            return state?.status.loggedIn;
        },
        isMember(state) {
            return !!state?.user?.attributes.instrument;
        },
        getBrideGroomToken(state) {
            return state?.user?.attributes.brideGroomToken;
        },
        isChangingPassword(state) {
            return state?.user?.attributes?.changingPassword;
        }
    },
    actions: {
        login({ commit }, loginData) {
            return loginService.login(loginData).then((response) => {
                    commit('loginSuccess', response);
                    return Promise.resolve(response);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        logout({ commit }) {
            loginService.logout();
            commit('logout');
        },
    },
    mutations: {
        loginSuccess(state, response) {
            state.status.loggedIn = true;
            let user = response.data;
            sessionStorage.setItem('user', JSON.stringify(user));
            state.user = user;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
        }
    }
};
