import api from "@/service/api";

export default {

    getSystemVersion: () => {
        return api.get(`/system/info`);
    },

    submitPasswordRecovery: (username) => {
        return api.post('/system/password/recovery', {username: username});
    },

    confirmPasswordRecovery: (username, recoveryCode) => {
        return api.post('/system/password/recovery/confirmation', {
            username: username,
            recoveryCode: recoveryCode
        });
    },

    changePassword: (username, newPassword, newPasswordConfirmation) => {
        return api.post('/system/password/change', {
            username: username,
            newPassword: newPassword,
            newPasswordConfirmation: newPasswordConfirmation
        });
    },

    updatePricingTable: (config) => {
        return api.put('/system/price-table', config);
    },

    getCurrentPriceTable: () => {
        return api.get('/system/price-table');
    }
};