<template>
  <div class="flex navbar">
    <div class="flex align-items-self-start justify-content-start m-2">
        <router-link to="" @click="toggleSidebar()">
          <i class="pi pi-bars"></i>
        </router-link>
        <span>|  QST Audições</span>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue'
export default({
    name: "NavbarComponent",
    components: {},
    props: {
        alt: String,
        logo: String
    },
  setup() {
    const showSidebar = ref(false);
    return{showSidebar};
  }, 
  methods: {
    toggleSidebar() {
      this.showSidebar = !this.showSidebar;
      this.$emit('toggleSidebar', this.showSidebar);
    }
  },
})
</script>

<style scoped> 

 .navbar {
  background-color: #1E1E1E;
  font-size: 1.4rem;
  font-family: Arial, Helvetica, sans-serif;
}

.navbar a {
  font-size: 16px;
  color: #D0B45E;
  text-decoration: none;
  margin-right: 0.5rem;
}

.navbar span {
  color: #D0B45E;
}

.navbar i {
  font-size: 1.8rem;
}

.navbar a:hover, i:hover {
  text-shadow: 0 0 0.7em rgba(247, 216, 124, 0.7);
  cursor: pointer;
}

@media (max-width: 480px) {
  .navbar {
    font-size: 1.2rem;
}

.navbar i {
  font-size: 1.3rem;
}

}

</style>