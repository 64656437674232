<template>
    <main>
      <Toast position="bottom-right" group="br" :breakpoints="{'920px': {width: '100%', right: '0'}}"/>
        <div>
          <NavbarIntegrantes v-if="isAuthenticated && isMember" :logo="logo_src" :alt="app_name" @toggle-sidebar="toggleSidebar"/>
          <SidebarIntegrantes v-if="isAuthenticated && isMember" ref="sidebar"/>
        </div>
        <router-view/>
        <ProgressSpinner v-if="spin"/>
        <Footer v-if="!route?.name?.includes('login')"/>
    </main>
</template>

<script>
import {ref, watch} from 'vue'
import NavbarIntegrantes from './components/NavbarIntegrantes.vue'
import {useRoute} from 'vue-router'
import {useStore} from 'vuex';
import Toast from "primevue/toast";
import {useToast} from 'primevue/usetoast';
import SidebarIntegrantes from './components/generics/SidebarIntegrantes.vue';
import {computed} from '@vue/reactivity';
import Footer from './components/Footer.vue';
import ProgressSpinner from 'primevue/progressspinner';

export default({
  components:{NavbarIntegrantes, Toast, SidebarIntegrantes, Footer, ProgressSpinner},
  setup() {
    const logo_src = ref("./img/Logo.png");
    const app_name = ref("Projeto QST");
    const store = useStore();
    const route = useRoute();
    const toast = useToast();

    const isAuthenticated = computed(() => store.getters['auth/isAuthenticated']);
    const isMember = computed(() => store.getters['auth/isMember']);
    const spin = computed(() => store.getters['spin/isSpinning']);


    // Altera o plano de fundo para a imagem de background
    watch(() => route.name, () => {
      if(route.name && route.name.includes('login')) {
        document.getElementById("app").style.backgroundImage = "url('img/background.jpg')";
        document.getElementById("app").style.backgroundPosition = "center center";
        document.getElementById("app").style.backgroundRepeat = "no-repeat";
        document.getElementById("app").style.backgroundAttachment = "fixed";
        document.getElementById("app").style.backgroundSize = "cover";
        document.getElementById("app").style.backgroundColor = "#464646;";
        document.getElementById("app").style.width = "100vw";
        document.getElementById("app").style.height = "100vh";
      } else {
        document.getElementById("app").removeAttribute('style');
        document.getElementById("app").style.backgroundColor = "#d6d6d6";
        document.getElementById("app").style.width = "100vw";
        document.getElementById("app").style.height = "100vh";
      }
    }, { immediate: true });

    // Verifica por mensagens recebidas do Interceptador do Axios em api.js
    watch(() => store.getters['message/getMessage'], (message) => {
      if(message) {
        toast.add(message);
      }
    });

    return{logo_src, app_name, route, store, isAuthenticated, isMember, toast, spin}
  },

  methods: {
    toggleSidebar() {
      this.$refs.sidebar.toggleSidebar();
    }
  },
})
</script>

<style>

  #app {
      background-color: #d6d6d6;
      width: 100vw;
      height: 100vh;
      overflow: auto;
    }

  .p-sidebar {
        background: #3e3d3d !important;
  }

  .p-panelmenu .p-panelmenu-content {
    border: none !important;
    background: #3e3d3d !important;
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
    color: #D0B45E !important;
    font-weight: 600 !important;
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
    color: #D0B45E !important;
  }

  .p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
    background: #746f60 !important;
    transition: 0.5s !important;
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
    background: #746f60 !important;
    transition: 0.5s !important;
  }

  .container-form{
    padding: 5%;
  }
  
  #cadastro-form{
        color: #5c5c5c;
        margin: 0 auto;
  }

  .inputs-form {
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        background-color: #eeeeee;
        padding: 20px;
        margin: 0 auto;
        margin-top: 40px;
        margin-bottom: 40px;
        border-radius: 10px;
  }

  .listagem-form {
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        background-color: #eeeeee;
        margin: 0 auto;
        margin-top: 10%;
        margin-bottom: 5%;
        border-radius: 10px;
  }

  .title-form{
        width: 80%; 
        font-size: 75%;
        margin: 0 auto;
        padding: 10px;
        margin-top: 40px;
        border-bottom: 1px solid #b3b3b3;

    }

  .p-checkbox .p-checkbox-box.p-highlight,
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover,
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover,
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    border-color: #525252!important;
  }

  .p-checkbox .p-checkbox-box.p-highlight,
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    background: transparent !important;
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    box-shadow: 0 0 0 0.05rem rgb(100, 100, 100) !important;
  }

  .p-button {
    background: #1E1E1E !important;
    border: 1px solid #1E1E1E !important;
    color: #D0B45E !important;
    font: 18px bold !important;
    border-radius: 5px !important;
  }

  .p-button-outlined {
    background: transparent !important;
    border: 1px solid #1E1E1E !important;
    color: #1E1E1E !important;

  }

  .p-button:hover{
    background:  #0f0f0f !important;
  }
  
  .p-button-outlined:hover{
    background: transparent !important;

  }

  label{
    margin-bottom: 10px;
  }

  .secondary{
    background: #D0B45E !important;
    border: 1px solid #D0B45E !important;
    color: #1E1E1E !important; 

  }

  .secondary:hover{
    background-color: #af9440 !important;
    border: 1px solid #af9440 !important;
  }

  .p-progress-spinner-svg {
    height: 10% !important;
    width: 10% !important;
    position: fixed !important;
  }

  .p-progress-spinner {
    position: static !important;
  }


@media (max-width: 480px) {
        
    .container-form{
      margin: 0 auto;
    }
  
    .input-container{
        width: 100%;
    }
    
    .title-form{
        width: 100%; 
        font-size: 65%;
        margin: 0 auto;
        padding: 10px;
        margin-top: 20px;
        text-align: center;
    } 

    .listagem-form{
        margin-top: 5vh;
    }
}

@media (max-width: 350px) {
        
       .container-form{
          margin: 0 auto;
        }
      
        .input-container{
            width: 100%;
        }
        
        .title-form{
            width: 100%; 
            font-size: 65%;
            margin: 0 auto;
            padding: 10px;
            margin-top: 20px;
            text-align: center;
        } 
    
    }

</style>

