import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import PrimeVue from 'primevue/config';
import DialogService from 'primevue/dialogservice';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import { VueReCaptcha } from 'vue-recaptcha-v3'


import 'primeflex/primeflex.css'; //primeflex
import 'primeicons/primeicons.css'; //icons
import 'primevue/resources/primevue.min.css'; //core css
import 'primevue/resources/themes/saga-orange/theme.css'; //theme

const app = createApp(App)

app.use(store)
app.use(router)
app.use(PrimeVue, {
    locale: {
        clear: 'Limpar',
        dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
        dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
        dayNamesMin: ["Do","Se","Te","Qua","Qui","Se","Sa"],
        monthNames: ["Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro"],
        monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun","Jul", "Ago", "Set", "Out", "Nov", "Dez"],
        chooseYear: 'Escolha o Ano',
        chooseMonth: 'Escolha o Mês',
        chooseDate: 'Escolha a Data',
        am: 'am',
        pm: 'pm',
        today: 'Hoje',
        weekHeader: 'Sem',
        firstDayOfWeek: 0,
        dateFormat: 'dd/mm/yy',
        weak: 'Semana'
    }
});
app.use(DialogService);
app.use(VueReCaptcha, { siteKey: '6LexcQEoAAAAADgEX2KitlhLc5E32WuiJvqPDBw6' });
app.use(ToastService);
app.directive('tooltip', Tooltip);

app.mount('#app')
