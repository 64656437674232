export const spin = {
    namespaced: true,
    state: {spin: false},
    getters: {
        isSpinning(state) {
            return state.spin;
        }
    },
    actions: {
        noSpinSpin({ commit }, spin) {
            commit('changeState', spin);
        }
    },
    mutations: {
        changeState(state, spin) {
            state.spin = spin;
        }
    }
};
