<template>
    <footer>
        <div class="footer-container">
            <div class="content">
                <div class="grid">
                    <div class="col-12 md:col-8 lg:col-4">
                        <img src="../assets/Logo-login.webp" alt="">
                    </div>

                    <div class="col-12 md:col-8 lg:col-4">
                        <h2>Contatos</h2>
                        <div class="texto">

                                <i class="pi pi-whatsapp"></i>
                                <a class="link-contato" href="https://wa.me/5537999586101" target="_blank">(37) 99958-6101</a>
                        </div>

                        <div class="texto">
                               <i class="pi pi-envelope"></i>
                              <a class="link-contato" href="mailto:contato@quintetosaldaterra.com.br">contato@quintetosaldaterra.com.br</a>
                        </div>
                    </div>

                    <div class="col-12 md:col-8 lg:col-4">
                        <h2>Redes Sociais</h2>
                        <div class="texto texto-top">
                            <p>Nos acompanhe em nossas redes sociais:</p>
                        </div>
                        <div class="texto texto-top">
                            <div class="redes-sociais">
                                    <a href="https://www.instagram.com/quintetosaldaterra/" target="_blank">
                                        <i class="pi pi-instagram"></i>
                                    </a>

                                    <a href="https://www.youtube.com/c/QuintetoSaldaTerra" target="_blank">
                                        <i class="pi pi-youtube"></i>
                                    </a>

                                    <a href="https://www.facebook.com/quintetosaldaterra" target="_blank">
                                        <i class="pi pi-facebook"></i>
                                    </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </div>

        <div class="copyright-container">
            <p>&COPY; Copyright 2024. Todos os direitos reservados.</p>
			<ProjectVersionComponent/>
		</div>
    </footer>
</template>

<script>
import ProjectVersionComponent from "@/components/generics/ProjectVersionComponent";

export default {
    name: "FooterComponent",
	components: {ProjectVersionComponent},
    setup() {
        
    },
}
</script>

<style scoped>
    .footer-container{
        color: #fff;
        font-size: 0.8em;

        background: #414141;

        padding: 40px;
    }

    .content{
        margin: 0 auto;
        padding-top: 3%;
        width: 80%;
    }

    img{
        height: 100px;
        width: 100px;
    }
    .copyright-container{
        background: #2E2E2E;
		display: flex;
		flex-direction: column;
        color: #fff;
        font-size: 0.8em;
        text-align: center;
        padding: 1.5%;
    }

    h2{
        margin-top: 1%;
    }


    .texto{
        color: #C9C9C9;
        padding-top: 5%;
    }

    .texto a {
      margin-left: 2%;
    }
    .link-contato {
        text-decoration: none;
        color: #C9C9C9;
    }

    .redes-sociais{
        padding-top: 10px;
    }
    
    .redes-sociais .pi{
        font-size: 2.0em;
        margin-right: 10px;
    }

    .redes-sociais a{
        color: #C9C9C9;
    }

    @media (max-width: 960px) {
        .link-contato{
            margin-left: 5%;
        }
        .content{
            width: 100%;
        }

    }
    
    @media (max-width: 640px) {
        img{
            height: 75px;
            width: 75px;
        }

        .copyright-container{
            font-size: 0.5em;
        }

        .redes-sociais .pi{
            margin-right: 10px;
        }

        .link-contato{
            margin-left: 0;
        }
    }

    @media (min-width: 1200px){
      .footer-container{
        font-size: 1.2em;
      }
    }

</style>