import {createStore} from 'vuex'
import {auth} from "@/store/auth.module";
import {message} from './message.module';
import {spin} from "@/store/spin.module";

const store = createStore({
  modules: {
    auth,
    message,
    spin
  },
});

export default store;
